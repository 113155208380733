<template>
  <div class="cr-card-detail-option" v-if="show">
    <v-icon>mdi-phone</v-icon>

    <div class="cr-edit-wrapper" :class="{ 'py-2': isEdit }">
      <Phone
        ref="phone"
        v-for="(phone, idx) in list"
        :key="idx"
        :phone="phone"
        :firstItem="idx === 0"
        :lastItem="idx + 1 === list.length"
        @update:value="v => $set(list, idx, v)"
        @click:add="addPhone"
        @click:delete="$delete(list, idx)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isBlank } from "@/commons/utils/validation";
import Phone from "./Phone.vue";

export default {
  components: { Phone },
  data({ $store: { getters: g } }) {
    const { telTypeList: types, telList } = g["customerCardDetail/detail"];
    return {
      list: telList?.map((number, idx) => ({ number, type: types[idx] })) || []
    };
  },
  computed: {
    ...mapGetters("customerCardDetail", ["isEdit", "detail"]),
    show() {
      return true;
      /*
      if (this.isEdit) return true;
      return this.list.length > 0;
       */
    }
  },
  watch: {
    isEdit(isEdit) {
      if (isEdit) {
        if (!this.list?.length) this.list = [{ type: "cell" }];
        return;
      }

      const { telTypeList: types, telList } = this.detail;
      this.list =
        telList?.map((number, idx) => ({ number, type: types[idx] })) || [];
    }
  },
  methods: {
    addPhone() {
      this.list = [...this.list, { type: "cell" }];
      this.$nextTick(() => {
        const idx = this.list.length - 1;
        this.$refs.phone[idx]?.focus();
      });
    },
    getData() {
      if (!this.isEdit) return;

      const telList = [];
      const telTypeList = [];
      this.list.forEach(phone => {
        if (isBlank(phone.number)) return;

        telList.push(phone.number);
        telTypeList.push(phone.type);
      });

      return { telList, telTypeList };
    }
  }
};
</script>
