<template>
  <div class="d-flex">
    <div
      v-if="!isEdit && phone.number"
      class="d-flex align-center"
      :class="{ 'mb-3': !lastItem }"
    >
      <span>{{ phone.number }}</span>
      <v-icon>mdi-circle-small</v-icon>
      <span>{{ types[phone.type] }}</span>
    </div>

    <div v-if="isEdit" class="d-flex flex-grow-1">
      <div class="cr-select">
        <Select :items="typeList" defaultValue="cell" :value.sync="_type" />
      </div>

      <TextField
        ref="number"
        showDeleteBtn
        label="전화번호"
        class="flex-grow-1"
        :value.sync="_number"
        :showAddBtn="firstItem"
        :class="{ 'mb-2': !lastItem }"
        @click:add="$emit('click:add')"
        @click:delete="$emit('click:delete')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-select {
  width: 180px;
  margin-right: 4px;
}
</style>

<script>
import { mapGetters } from "vuex";
import Select from "@/customer/views/components/list/common/Select.vue";
import TextField from "@/customer/views/components/list/common/TextField.vue";

export default {
  components: { Select, TextField },
  props: {
    phone: {
      type: Object,
      default: () => ({})
    },
    firstItem: {
      type: Boolean,
      default: false
    },
    lastItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      types: {
        cell: this.$t("contact.핸드폰"),
        work: this.$t("contact.회사"),
        home: this.$t("contact.집"),
        fax: this.$t("contact.팩스"),
        voice: this.$t("contact.기타")
      }
    };
  },
  computed: {
    ...mapGetters("customerCardDetail", ["isEdit"]),
    typeList() {
      const { types } = this;
      return Object.keys(types).map(value => ({ value, name: types[value] }));
    },
    _type: {
      get() {
        return this.phone?.type || "cell";
      },
      set(type) {
        const { number } = this.phone;
        this.$emit("update:value", { number, type });
      }
    },
    _number: {
      get() {
        return this.phone?.number || "";
      },
      set(number) {
        const { type } = this.phone;
        this.$emit("update:value", { number, type });
      }
    }
  },
  methods: {
    focus() {
      this.$refs.number?.$refs?.textfield?.focus();
    }
  }
};
</script>
